import { DateTime } from 'luxon';
import { getBrowserLang } from '@common/utils/lang';
import {
  transformLuxonDateToTuiDate,
  transformTuiToLuxon,
} from '@common/utils/date-form-transform';

export const dateTimeWithUserTimezone = (): DateTime => {
  const timezone = sessionStorage.getItem('timezone') || 'utc';
  return DateTime.local({ zone: timezone });
};

export const formatIsoDateToTuiWithTimezone = (isoDatetime: string) => {
  const timezone = sessionStorage.getItem('timezone') || 'utc';

  return transformLuxonDateToTuiDate(DateTime.fromISO(isoDatetime).setZone(timezone), {});
};

export const formatDefaultDateFromISO = (
  value: string | null | undefined,
  date: string | null | undefined = 'dd.MM.yyyy',
  time: string | null | undefined = 'HH:mm:ss',
) => {
  if (!value) return '-';

  // TODO: КОСТЫЛЬ. Временно. Потом убрать(когда поправит бекенд)
  if (!value.endsWith('Z')) {
    if (value.endsWith('+00:00')) {
      value = value.replace('+00:00', 'Z');
    } else {
      value = value.concat('Z');
    }
  }
  const timezone = sessionStorage.getItem('timezone') || 'utc';
  const dateTime = DateTime.fromISO(value, { zone: timezone });

  if (!dateTime.isValid) return '-';

  const format = `${date} ${time}`;

  return dateTime.toFormat(format);
};

export const dateNowForFilename = () => {
  return DateTime.local().toFormat('yyyy-MM-dd_HH-mm-ss');
};

export const formatDefaultDateFromSeconds = (
  value: number | null | undefined,
  date: string | null | undefined = 'dd.MM.yyyy',
  time: string | null | undefined = 'HH:mm:ss',
) => {
  if (!value) return '-';

  const timezone = sessionStorage.getItem('timezone') || 'utc';
  const dateTime = DateTime.fromSeconds(value, { zone: timezone });

  if (!dateTime.isValid) return '-';

  const format = `${date} ${time}`;

  return dateTime.toFormat(format);
};

export const formatDefaultDateFromTui = (
  periodDateStart: any[],
  periodDateEnd: any[],
  date: string | null | undefined = 'dd.MM.yyyy',
  time: string | null | undefined = 'HH:mm:ss',
) => {
  const locale = getBrowserLang();
  const format = `${date} ${time}`;
  const startDate = periodDateStart?.[0]
    ? transformTuiToLuxon(periodDateStart[0], { time: periodDateStart[1] }).toFormat(format, {
        locale,
      })
    : 'Choose start date';

  const endDate = periodDateEnd?.[0]
    ? transformTuiToLuxon(periodDateEnd[0], { time: periodDateEnd[1] }).toFormat(format, { locale })
    : 'Choose end date';

  return `${startDate} - ${endDate}`;
};

export const formatDateToRelativeFromISO = (value: string | null | undefined) => {
  if (!value) return '-';

  // TODO: КОСТЫЛЬ. Временно. Потом убрать(когда поправит бекенд)
  if (!value.endsWith('Z')) {
    if (value.endsWith('+00:00')) {
      value = value.replace('+00:00', 'Z');
    } else {
      value = value.concat('Z');
    }
  }

  const timezone = sessionStorage.getItem('timezone') || 'utc';
  const dateTime = DateTime.fromISO(value, { zone: timezone });

  if (!dateTime.isValid) return '-';

  return dateTime.toRelative();
};
