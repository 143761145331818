<ng-container [formGroup]="formGroup" *tuiLet="formGroup.controls as controls">
  <div class="select-period">
    <tui-radio-block
      class="select-period"
      *ngFor="let period of availablePeriods"
      contentAlign="right"
      formControlName="periodValue"
      size="s"
      [hideRadio]="true"
      [item]="period.value"
    >
      {{ period.title | translate }}
    </tui-radio-block>
  </div>
  <div
    class="hidden"
    [@tuiHeightCollapse]="getAnimation(500)"
    *ngIf="controls.periodValue.value !== AvailableDateTimePeriod.Today"
  >
    <div
      class="select-period__recently hidden"
      *ngIf="controls.periodValue.value === AvailableDateTimePeriod.Recently"
    >
      <div class="select-period__recently-input-value">
        <button (click)="recentlyPeriodDecrement()" [disabled]="formGroup.disabled">
          <tui-svg [src]="'common/assets/icons/minus.svg'"></tui-svg>
        </button>
        <tui-input-number
          formControlName="recentlyPeriod"
          tuiTextfieldSize="s"
          [min]="1"
        ></tui-input-number>
        <button (click)="recentlyPeriodIncrement()" [disabled]="formGroup.disabled">
          <tui-svg [src]="'common/assets/icons/plus.svg'"></tui-svg>
        </button>
      </div>

      <tui-select
        class="b-form"
        formControlName="recentlyPeriodType"
        tuiTextfieldSize="s"
        [valueContent]="stringifyValue(availableRecentlyPeriodTypes)"
      >
        <ng-template tuiDataList>
          <tui-data-list>
            <button
              *ngFor="let item of availableRecentlyPeriodTypes"
              tuiOption
              [value]="item.value"
            >
              {{ item.title | translate }}
            </button>
          </tui-data-list>
        </ng-template>
      </tui-select>
      <tui-checkbox-labeled formControlName="currentPeriod">
        {{ 'Current period' | translate }}
      </tui-checkbox-labeled>
    </div>

    <div
      class="select-period__exact hidden"
      *ngIf="controls.periodValue.value === AvailableDateTimePeriod.Exact"
    >
      <tui-input-date-time
        formControlName="periodDateStart"
        [pseudoFocus]="!controls.periodDateStart.valid && controls.periodDateStart.touched"
        [max]="currentDatetime"
        (focusedChange)="onFocusedChangePeriodDateStart($event)"
        tuiTextfieldSize="s"
      >
        <input tuiTextfield />
      </tui-input-date-time>

      <div class="separator">—</div>

      <tui-input-date-time
        formControlName="periodDateEnd"
        [pseudoFocus]="!controls.periodDateEnd.valid && controls.periodDateEnd.touched"
        [min]="controls.periodDateStart.value"
        [max]="currentDatetime"
        (focusedChange)="onFocusedChangePeriodDateEnd($event)"
        tuiTextfieldSize="s"
      >
        <input tuiTextfield />
      </tui-input-date-time>
    </div>
  </div>
</ng-container>
