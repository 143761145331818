import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { AppService } from '@services/app.service';
import { environment } from '../../environments/environment';
import { log } from 'echarts/types/src/util/log';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  requestMethodsAutoload: string[] = ['POST', 'PUT', 'DELETE'];

  skipRequestsUrl: string[] = [
    `${environment.apiUrl}geocoding/reverse/`,
    `${environment.apiUrl}messages/get`,
    `${environment.apiUrl}messages/stat`,
    `${environment.apiUrl}tracks/create/`,
    `${environment.apiUrl}objects/send_command/`,
    `${environment.apiUrl}cms/accounts/excel/`,
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
  ];

  skipRetransmissionsUrl = (request: HttpRequest<any>) => {
    return (
      request.url.startsWith(`${environment.apiUrl}cms/retranslators/`) &&
      (request.url.endsWith('/retransmissions/') ||
        request.url.endsWith('/stop/') ||
        request.url.endsWith('/pause/') ||
        request.url.endsWith('/resume/'))
    );
  };

  constructor(private appService: AppService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAutoLoad =
      this.requestMethodsAutoload.includes(request.method) &&
      !this.skipRequestsUrl.includes(request.url) &&
      !request.url.includes('/activate/') &&
      !this.skipRetransmissionsUrl(request);
    if (isAutoLoad) {
      this.appService.isLoading.next(true);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (isAutoLoad) {
          this.appService.isLoading.next(false);
        }
      }),
    );
  }
}
