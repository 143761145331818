import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, tap } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UsersService } from '@services/users.service';
import { getCurrentUser, storeCurrentUser } from '@store/actions/current_user.actions';
import { WebSocketService } from '@services/websocket.service';
import { TuiLanguageSwitcher } from '@taiga-ui/i18n';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';

@Injectable()
export class CurrentUserEffects {
  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentUser),
      exhaustMap(() =>
        this.usersService.getCurrentUser().pipe(
          tap((user: any) => {
            this.translate.use(user.language);
            this.switcher.setLanguage(user.language === 'ru' ? 'russian' : 'english');

            localStorage.setItem('lang', user.language);
            sessionStorage.setItem(
              'timezone',
              `UTC${user.timezone >= 0 ? '+' : ''}${user.timezone}`,
            );
          }),
          map((currentUser: any) => {
            this.websocketService.connectUserSocket(currentUser);
            return storeCurrentUser({ currentUser });
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  getSegmentCurrentRoute(): string {
    const currentUrl = this.router.routerState.snapshot.url;
    return currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
  }

  constructor(
    @Inject(TuiLanguageSwitcher) private readonly switcher: TuiLanguageSwitcher,
    public translate: TranslateService,
    private actions$: Actions,
    private usersService: UsersService,
    private websocketService: WebSocketService,
    private appService: AppService,
    private router: Router,
  ) {}
}
