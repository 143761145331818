import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TUI_SANITIZER, TuiModeModule, TuiThemeNightModule } from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TaigaModule } from './taiga-ui.module';
import { ApiInterceptor } from './api.interceptor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ObjectsEffects } from '@store/effects/objects.effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeviceModelsEffects } from '@store/effects/device_models.effects';
import { CurrentUserEffects } from '@store/effects/current_user.effects';
import { NGX_ECHARTS_CONFIG } from 'ngx-echarts';
import { GeozonesEffects } from '@store/effects/geozones.effects';
import { NotificationsEffects } from '@store/effects/notifications.effects';
import { TuiLanguageName } from '@taiga-ui/i18n/interfaces';
import { tuiLanguageSwitcher } from '@taiga-ui/i18n/switch';
import { DriversEffects } from '@store/effects/drivers.effects';

import { environment } from 'src/environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import { SidePageModule } from '@common/components/sidepage/sidepage.module';
import { BuildingsEffects } from '@store/effects/buildings.effects';
import { ErrorInterceptor } from '@common/interceptors/error.interceptor';
import { Router } from '@angular/router';
import { LoaderInterceptor } from '@common/interceptors/loader.interceptor';
import { ImportObjectsEffects } from '@store/effects/import_objects.effects';
import { mainReducers } from '@store/types/store-types';

const production = environment.production;

function getProviders() {
  const providers: any = [
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({ echarts: () => import('echarts') }),
    },
    tuiLanguageSwitcher(async (language: TuiLanguageName): Promise<unknown> => {
      switch (language) {
        case `ru`:
        case `russian`:
          return import(`node_modules/@taiga-ui/i18n/languages/russian`);
        default:
          return import(`node_modules/@taiga-ui/i18n/languages/english`);
      }
    }),
  ];
  if (production) {
    providers.push(
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    );
  }
  return providers;
}

console.log('PRODUCTION', production);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './common/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot(mainReducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([
      ObjectsEffects,
      DeviceModelsEffects,
      CurrentUserEffects,
      GeozonesEffects,
      NotificationsEffects,
      DriversEffects,
      BuildingsEffects,
      ImportObjectsEffects,
    ]),
    AppRoutingModule,
    BrowserAnimationsModule,
    TaigaModule,
    HttpClientModule,
    TranslateModule.forRoot({ defaultLanguage: 'en' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SidePageModule,
    TuiThemeNightModule,
    TuiModeModule,
  ],
  providers: getProviders(),
  bootstrap: [AppComponent],
})
export class AppModule {}
